<template>
  <div class="wrap">
    <div class="wrap_box1">
      <el-input
        v-model.number="input"
        placeholder="请输入还原的用户手机号"
      ></el-input>
      <el-button @click="restore" class="btn">一键还原</el-button>
    </div>
  </div>
</template>

<script>
import { restore } from '@/api/hr24_api/api_all.js'
export default {
  data() {
    return {
      input: '',
    }
  },
  methods: {
    restore() {
      restore(this.input).then((res) => {
        if (!res.code) {
          this.$message.success(res.msg)
        } else {
          this.$message.error(res.msg)
        }
      })
    },
  },
}
</script>

<style scoped>
.wrap {
  padding-top: 54px;
}
.wrap_box1 {
  display: flex;
}

.el-input {
  width: 30%;
  margin-right: 10px;
}
</style>
